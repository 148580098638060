import { template as template_15e61084296f4f12b90e023bb7e2016e } from "@ember/template-compiler";
const FKText = template_15e61084296f4f12b90e023bb7e2016e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
