import { template as template_11c8ba1fddf94574828e2f8f6e6df49f } from "@ember/template-compiler";
const WelcomeHeader = template_11c8ba1fddf94574828e2f8f6e6df49f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
