import { template as template_30df53fdb4164c80b6a3b630b2562461 } from "@ember/template-compiler";
const FKLabel = template_30df53fdb4164c80b6a3b630b2562461(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
