import { template as template_b4aff61433c242da9b5008df7b7e3c30 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuCopyLinkButton = template_b4aff61433c242da9b5008df7b7e3c30(`
  <DButton
    class="post-action-menu__copy-link"
    ...attributes
    @action={{@buttonActions.copyLink}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.copy_action"}}
    @title="post.controls.copy_title"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuCopyLinkButton;
